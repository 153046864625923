<template>
  <form @submit.prevent="cadastrarServico">
    <h6 class="heading-small text-muted mb-4">Informações do Serviço</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="nomeServico">Nome do Serviço</label>
          <input type="text" id="nomeServico" class="form-control" placeholder="Nome do Serviço"
            v-model="servico.nomeServico" required />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="descricaoServico">Descrição</label>
          <input type="text" id="descricaoServico" class="form-control" placeholder="Descrição do Serviço"
            v-model="servico.descricaoServico" required />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <label for="tipoServico">Tipo do Serviço</label>
          <input type="text" id="tipoServico" class="form-control" placeholder="Tipo do Serviço"
            v-model="servico.tipoServico" required />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="situacao">Situação</label>
          <select v-model="servico.situacao" id="situacao" class="form-control">
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack">VOLTAR</a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">SALVAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  components: {
    ArgonAlert
  },
  props: {
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return {
      user: userData,
      servico: {
        nomeServico: '',
        descricaoServico: '',
        tipoServico: '',
        situacao: 1,
        // idEmpresa: this.user.idEmpresa || 1,
      },
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    cadastrarServico() {
      const payload = {
        nomeServico: this.servico.nomeServico,
        descricaoServico: this.servico.descricaoServico,
        tipoServico: this.servico.tipoServico,
        situacao: this.servico.situacao,
        idEmpresa: this.user.idEmpresa
      };

      axios.post(`${process.env.VUE_APP_API_ENDPOINT}/servicos`, payload, {
        headers: {
            Authorization: `Bearer ${this.user.token}`
          }
      }).then(response => {
          if (response.status === 201) {
            this.infoMessage = "Serviço salvo com sucesso.";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack('cadastrar');
              this.resetForm();
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Erro ao salvar serviço:", error);
          this.errorMessage = "Erro ao salvar serviço.";
          this.showAlertError = true;
        });
    },
    resetForm() {
      this.servico = {
        nomeServico: '',
        descricaoServico: '',
        tipoServico: '',
        situacao: 1,
        idEmpresa: this.user.idEmpresa || 1
      };
    },
    goBack(acao) {
      this.closeModal(acao);
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

<template>
  <form @submit.prevent="cadastrarSaida">
    <h6 class="heading-small text-muted mb-4">Saída do Financeiro</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="descricao" class="mt-4">Descrição</label>
          <input type="text" id="descricao" class="form-control" required v-model="saida.descricao" />
        </div>

        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="nomeColaborador" class="mt-4">Colaborador (Opcional)</label>
          <el-select-v2 id="nomeColaborador" v-model="selectedColaborador" loading-text="Buscando"
            no-data-text="Não encontrado" no-match-text="Não encontrado!" clearable filterable remote
            :remote-method="fetchColaboradoresFiltro" :options="colaboradorOptions" :loading="loading"
            placeholder="Informe caso queira atrelar uma saída ao Colaborador">
          </el-select-v2>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-3 mt-3 mt-md-0">
          <label for="tipoSaida">Tipo de Saída</label>
          <select id="tipoSaida" v-model="saida.codigoTipoSaida" class="form-control">
            <option v-for="tipoSaida in tipoSaidaOptions" :key="tipoSaida.value" :value="tipoSaida.value">
              {{ tipoSaida.label }}
            </option>
          </select>
        </div>

        <div class="col-12 col-md-3 mt-3 mt-md-0 ">
          <label for="data">Data</label>
          <input type="date" id="data" class="form-control" v-model="saida.data" />
        </div>

        <div class="col-12 col-md-3 mt-3 mt-md-0">
          <label for="meioPagamento">Meio de Pagamento</label>
          <select id="meioPagamento" v-model="saida.codigoMeioPagamento" class="form-control">
            <option v-for="meio in meioPagamentoOptions" :key="meio.value" :value="meio.value">
              {{ meio.label }}
            </option>
          </select>
        </div>

        <div class="col-12 col-md-3 mt-3 mt-md-0">
          <label for="valor">Valor (R$):</label>
          <money3 class="form-control" v-model="saida.valor" v-bind="config"></money3>
        </div>


      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-12 mt-3 mt-md-0">
          <label for="observacoes">Observações:</label>
          <textarea id="observacoes" v-model="saida.observacoes" class="form-control" />
        </div>
      </div>



      <div class="row mt-4">
        <div class="col-2 col-md-2 text-left">
          <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack">VOLTAR</a>
        </div>
        <div class="col-10 col-md-10 d-flex justify-content-end">
          <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
            variant="success">SALVAR</button>
        </div>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";
import { Money3Component } from 'v-money3'


export default {
  components: {
    ArgonAlert,
    money3: Money3Component
  },
  props: {
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')),
      saida: {
        idEmpresa: null,
        valor: 0,
        codigoTipoSaida: 4,
        codigoMeioPagamento: 1,
        observacoes: '',
        data: new Date().toISOString().split('T')[0],
        descricao: null,
        idColaborador: null,
      },
      tipoSaidaOptions: [
        { value: 0, label: 'Pagamento mensal - (colaborador)' },
        { value: 1, label: 'Pagamento diária (colaborador)' },
        { value: 2, label: 'Pagamento comissão (colaborador)' },
        { value: 3, label: 'Pagamento mensal + comissão (colaborador)' },
        { value: 4, label: 'Despesas avulsas em geral' },
        { value: 5, label: 'Vale adiantamento (colaborador)' },
        { value: 6, label: 'Energia' },
        { value: 7, label: 'Água' },
        { value: 8, label: 'Internet' },
        { value: 9, label: 'Compra/Reposição de produtos' },
        { value: 10, label: 'Compra mobília' },
        { value: 11, label: 'Estorno (Cliente)' },
        { value: 12, label: 'Estorno (Cliente)' }
      ],
      meioPagamentoOptions: [
        { value: 0, label: 'Dinheiro em Espécie' },
        { value: 1, label: 'PIX' },
        { value: 2, label: 'Cartão de Crédito - AVISTA' },
        { value: 3, label: 'Cartão de Crédito - PARCELADO' },
        { value: 4, label: 'Boleto' }
      ],
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: ',',
        decimal: '.',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      showAlert: false,
      showAlertError: false,
      clientOptions: [],
      selectedColaborador: null,
      selectOptions: [],
      colaboradorOptions: [],
      serviceOptions: [],
      loading: false,
      modalCadastrarClienteOpened: false,
      modalCadastrarCliente: null,
    };
  },
  mounted() {
  },
  methods: {
    fetchColaboradoresFiltro(query) {
      const pageNo = 0
      //console.log(query)
      if (query) {
        this.loadingColaborador = true;
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa,
            nome: query,
            pageNo: pageNo,
            pageSize: this.pageSize
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
          .then(response => {
            this.colaboradorOptions = response.data.colaboradores.map(colaborador => ({
              value: colaborador.id,
              label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
            }));
            this.loadingColaborador = false;
            this.colaboradores = response.data.colaboradores;
            this.totalElements = response.data.paginacao.totalElements;
            this.totalPages = Math.ceil(this.totalElements / this.pageSize);
          })
          .catch(() => {
            this.loadingColaborador = false;
          });
      }
    },
    async cadastrarSaida() {
      try {
        const dataSelecionada = new Date(this.saida.data);
        const formattedDate = dataSelecionada.toISOString().split('T')[0].split('-').reverse().join('/');
        this.saida.data = formattedDate;
        this.saida.idEmpresa = this.user.idEmpresa;
        this.saida.idColaborador = this.selectedColaborador;

        const response = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/financeiro/saida_financeiro`,
          this.saida, {
            headers: {
              Authorization: `Bearer ${this.user.token}`
            }
          }
        );

        if (response.status === 200 || response.status === 201) {
          this.infoMessage = "Saída no financeiro salva com sucesso.";
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
            this.goBack();
          }, 2000);
        }
      } catch (error) {
        console.error("Erro ao salvar saída no financeiro:", error);
        this.errorMessage = error.response.data;
        this.showAlert = false;
        this.showAlertError = true;
        setTimeout(() => {
          this.showAlertError = false;
        }, 2000);
      }
    },
    goBack() {
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

<script setup>

</script>
<template>
  <div class="py-1 container-fluid">
    <!-- <div class="row"> 
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Receita Anual" value="$53,000" description="Acumulado anual" :icon="{
          component: 'ni ni-money-coins',
          background: 'bg-gradient-primary',
          shape: 'rounded-circle',
        }
          " />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Entradas" value="2,300" description="Novembro/2024" :icon="{
          component: 'ni ni-world',
          background: 'bg-gradient-danger',
          shape: 'rounded-circle',
        }
          " />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Saídas" value="+3,462" description="Novembro/2024" :icon="{
          component: 'ni ni-paper-diploma',
          background: 'bg-gradient-success',
          shape: 'rounded-circle',
        }
          " />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Saldo em Caixa" value="$103,430" description="Novembro/2024" :icon="{
          component: 'ni ni-cart',
          background: 'bg-gradient-warning',
          shape: 'rounded-circle',
        }
          " />
      </div>
    </div>
    -->
    <div class="row">
      <div class="col-12">
        <EntradasFinanceiroEmpresa></EntradasFinanceiroEmpresa>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <SaidasFinanceiroEmpresa></SaidasFinanceiroEmpresa>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <FinanceiroGeralEmpresa></FinanceiroGeralEmpresa>
      </div>
    </div>
  </div>
</template>

<script>
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import EntradasFinanceiroEmpresa from "./components/financeiro/EntradasFinanceiroEmpresa.vue";
import SaidasFinanceiroEmpresa from "./components/financeiro/SaidasFinanceiroEmpresa.vue";
import FinanceiroGeralEmpresa from "./components/financeiro/FinanceiroGeralEmpresa.vue";


export default {
  components: {
    // MiniStatisticsCard,
    EntradasFinanceiroEmpresa,
    SaidasFinanceiroEmpresa,
    FinanceiroGeralEmpresa
  },
}
</script>

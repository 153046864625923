<template>
  <div class="card">
    <div class="card-header pb-0 border-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <h3 class="">Dados da Empresa</h3>
        </div>

        <div class="col-12 col-md-4 mt-3 mt-md-0">
          <button type="button" class="btn btn-primary btn-sm w-100 w-md-auto" style="float:right;"
            @click="visualizarServicos">
            Serviços da Empresa
          </button>
        </div>
      </div>
    </div>
    <hr class="my-1 " />
    <div class="card-body p-4">
      <VisualizarEmpresaForm></VisualizarEmpresaForm>
    </div>
  </div>
</template>

<script>

import VisualizarEmpresaForm from "../empresa/VisualizarEmpresaForm.vue";

export default {
  name: 'listar-colaboradores-empresa',
  components: {
    VisualizarEmpresaForm,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 3,
      user: JSON.parse(localStorage.getItem('userData')) || {},
      colaboradores: [],
      idColaboradorSelecionado: 0,
      totalElements: 0,
      totalPages: 0,
      modal: null,
      modalCadastroColaborador: null,
      modalOpened: false,
      modalCadastroColaboradorOpened: false,
      nomeColaborador: '',
      selectedEmployee: null,
      employeeOptions: [],
      loading: false,
      idEmpresaSelecionado: 1
    };
  },
  mounted() {

  },
  methods: {
    visualizarServicos() {
      this.$router.push({ path: '/servicos' });
    }
  }
};
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

h3 {
  font-size: 1.3rem;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.0rem;
  }

  .btn-responsive {
    margin-top: 5px;
    font-size: 0.775rem;
    padding: 0.65rem 0.6rem;
  }
}

@media (max-width: 414px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .nome-width {
    min-width: 230px;
  }
}
</style>

<template>
  <div class="card">
    <div class="card-header pb-0 border-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <h3 class="">Visão Geral - Mês/Ano</h3>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-0">
          <select class="form-control" v-model="selectedYear" @change="handleFiltersChange">
            <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
          </select>
        </div>
        <div class="col-12 col-md-2 mt-3 mt-md-0">
          <button type="button" disabled class="btn btn-success btn-sm w-100 w-md-auto" style="float:right;"
            @click="exportar">
            Exportar
          </button>
        </div>
      </div>
    </div>
    <hr class="my-1" />

    <div class="card-body px-0 pt-0 pb-0">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="nome-width text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descrição</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Entradas</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Saídas </th>
              <th
                class="text-uppercase text-secondary text-left text-xxs font-weight-bolder opacity-7 ps-2">
                Saldo em Caixa</th>
              <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell">Situação</th>
              <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Última Movimentação</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(financeiro, index) in financeiroList" :key="index">
              <td class="">
                <div class="px-3 py-1 ">
                    <h6 class="mb-0 text-sm text-wrap">{{ financeiro.descricao }}</h6>
                </div>
              </td>
              <td class="d-none d-md-table-cell d-none d-md-table-cell">
                <h6 class="text-sm mb-0">R$ {{ financeiro.totalEntradas.toFixed(2) }}</h6>
              </td>
              <td class="align-middle text-left d-none d-md-table-cell">
                <h6 class="text-sm mb-0">R$ {{ financeiro.totalSaidas.toFixed(2) }}</h6>
              </td>
              <td class="align-middle text-left">
                <h6 class="text-sm mb-0">R$ {{ financeiro.totalCaixa.toFixed(2) }}</h6>
              </td>
              <td class="align-middle text-center d-none d-md-table-cell">
                <h6 class="text-sm mb-0"> {{ financeiro.situacao }}</h6>
              </td>
              <td class="nome-width text-center d-none d-md-table-cell">
                <h6 class="mb-0 text-sm"> {{ financeiro.dataUltimaAlteracao }}</h6>
              </td>

              <!-- <td style="text-align: right;" class="align-middle text-center d-none d-md-table-cell">
                <button type="button" class="btn btn-responsive btn-success mt-3"
                  style="float: right; margin-right: 15px" @click.stop="visualizar(financeiro.id)">Visualizar</button>
              </td> -->
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="1" class="text-left">
                <div class="ml-4  text-wrap" style="margin-left: 15px; white-space: normal;">
                  Total de Entradas em {{ selectedYear }}: R$ {{ totalEntradasAnual?.toFixed(2) }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="1" class="text-left">
                <div class="ml-4  text-wrap" style="margin-left: 15px; white-space: normal;">
                  Total de Saídas em {{ selectedYear }}: R$ {{ totalSaidasAnual?.toFixed(2) }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="1" class="text-left">
                <div class="ml-4  text-wrap" style="margin-left: 15px; white-space: normal;">
                  Faturamento Total em {{ selectedYear }}: R$ {{ totalFaturamentoAnual?.toFixed(2) }}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalVisualizarFinanceiro" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Visualizar Mês Financeiro</h5>
          <button type="button" class="btn-close" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <VisualizarDetalhesFinanceiro :idSaida="idFinanceiroSelecionada" :closeModal="closeModal"
            :modalOpened="modalVisualizarFinanceiroOpened" />
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>
import axios from 'axios';
import VisualizarDetalhesFinanceiro from "../financeiro/VisualizarDetalhesFinanceiro.vue"
import { Modal } from 'bootstrap';

export default {
  name: "listar-atendimentos",
  components: {
    VisualizarDetalhesFinanceiro,
  },
  data() {
    const today = new Date();
    const currentYear = today.getFullYear();
    return {
      user: JSON.parse(localStorage.getItem('userData')) || {},
      idFinanceiroSelecionada: 0,
      selectedYear: currentYear,
      availableYears: [2024, 2025, 2026],
      modalVisualizarFinanceiro: null,
      modalVisualizarFinanceiroOpened: false,
      totalEntradasAnual: 0,
      totalSaidasAnual: 0,
      totalFaturamentoAnual: 0,
      ano: '',
      financeiroList: [],
    };
  },
  mounted() {
    this.fetchFinanceiroEmpresa();
    this.modalVisualizarFinanceiro = new Modal(this.$refs.modalVisualizarFinanceiro);
  },
  methods: {
    async fetchFinanceiroEmpresa() {
      const idEmpresa = this.user.idEmpresa;
      const year = this.selectedYear;

      let url = `${process.env.VUE_APP_API_ENDPOINT}/financeiro?empresaId=${idEmpresa}&ano=${year}`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        });

        const { totalEntradasAnual, totalSaidasAnual, totalFaturamentoAnual, ano, financeiros } = response.data;

        this.totalEntradasAnual = totalEntradasAnual || 0;
        this.totalSaidasAnual = totalSaidasAnual || 0;
        this.totalFaturamentoAnual = totalFaturamentoAnual || 0;
        this.ano = ano;

        this.financeiroList = financeiros.map((financeiro) => ({
          id: financeiro.id,
          mes: financeiro.mes,
          dataUltimaAlteracao: financeiro.dataUltimaAlteracao,
          descricao: financeiro.descricao,
          situacao: financeiro.situacao,
          totalCaixa: financeiro.totalCaixa,
          totalEntradas: financeiro.totalEntradas,
          totalSaidas: financeiro.totalSaidas,
        }));
      } catch (error) {
        this.financeiroList = [];
        console.error('Erro ao buscar dados no financeiro:', error);
      }
    }
    ,
    handleFiltersChange() {
      this.currentPage = 1;
      this.fetchFinanceiroEmpresa();
    },
    exportar() {
    },
    visualizar(idFinanceiro) {
      if (idFinanceiro) {
        console.log(idFinanceiro)
        this.idFinanceiroSelecionada = idFinanceiro;
        this.modalVisualizarFinanceiro.show();
        this.modalVisualizarFinanceiroOpened = true;
      }
    },
    closeModal() {
      this.modalVisualizarFinanceiro.hide();
      this.modalVisualizarFinanceiroOpened = false;
      this.fetchFinanceiroEmpresa(true);
    },
    getBackgroundColor(situacao) {
      switch (situacao) {
        case 'Concluído':
          return '#2dce89';
        case 'Agendado':
          return '#4284f5';
        case 'Cancelado - Cliente':
          return '#cf4104';
        case 'Cancelado - Colaborador':
          return '#cf4104';
        default:
          return '#047acf';
      }
    },
  }
};
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

h3 {
  font-size: 1.3rem;
}

.status-custom {
  color: #fff;
  font-size: 0.775rem;
  padding: 0.65rem 0.6rem;
  border-radius: 10px;
}

.label_total {
  margin-right: 60px
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.0rem;
  }

  .btn-responsive {
    margin-top: 5px;
    font-size: 0.775rem;
    padding: 0.65rem 0.6rem;
  }
}

@media (max-width: 414px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .nome-width {
    max-width: 0px;
  }

  .info_width {
    width: 100px;
  }

  .label_total {
    margin-right: 14px
  }
}
</style>

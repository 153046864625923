<template>
  <form @submit.prevent="cadastrarColaborador">
    <h6 class="heading-small text-muted mb-4">Informações Básicas</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label for="nomeColaborador">Nome</label>
          <input type="text" id="nomeColaborador" class="form-control" placeholder="Nome do Colaborador"
            v-model="colaborador.nome" required autofocus/>
        </div>
        <div class="col-12 col-md-5 mt-2 mt-md-0">
          <label for="emailColaborador">Email</label>
          <input type="email" id="emailColaborador" class="form-control" placeholder="email@exemplo.com"
            v-model="colaborador.email" />
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="cpfColaborador">CPF</label>
          <input type="text" id="cpfColaborador" class="form-control" placeholder="000.000.000-00"
            v-model="colaborador.cpf" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-3">
          <label for="rgColaborador">RG</label>
          <input type="text" id="rgColaborador" class="form-control" placeholder="MG-00.000.000"
            v-model="colaborador.rg" />
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="telefoneColaborador">Telefone</label>
          <input type="text" name="phone" id="telefoneColaborador" required class="form-control" v-model="colaborador.telefone" v-mask-phone.br>
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="nascimentoColaborador">Data de Nascimento</label>
          <input type="date" id="nascimentoColaborador" class="form-control" v-model="colaborador.dataNascimento" />
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="statusColaborador">Status</label>
          <select v-model="colaborador.statusColaborador" id="statusColaborador" class="form-control">
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>
    </div>

    <hr class="my-4">

    <h6 class="heading-small text-muted">Endereço</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="ruaColaborador">Rua</label>
          <input type="text" id="ruaColaborador" class="form-control" placeholder="Nome da Rua"
            v-model="colaborador.endereco.rua" />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="cidadeColaborador">Cidade</label>
          <input type="text" id="cidadeColaborador" class="form-control" placeholder="Nome da Cidade"
            v-model="colaborador.endereco.cidade" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <label for="estadoColaborador">Estado</label>
          <input type="text" id="estadoColaborador" class="form-control" placeholder="Nome do Estado"
            v-model="colaborador.endereco.estado" />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="cepColaborador">CEP</label>
          <input type="text" id="cepColaborador" class="form-control" placeholder="00000-000"
            v-model="colaborador.endereco.cep" />
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack()">VOLTAR</a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">SALVAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>



<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  components: {
    ArgonAlert,
  },
  props: {
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return {
      user: userData,
      colaborador: {
        statusColaborador: 1,
        cpf: '',
        rg: '',
        dataNascimento: null,
        nome: '',
        email: '',
        telefone: '',
        endereco: {
          rua: '',
          cidade: '',
          estado: '',
          cep: ''
        }
      },
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    cadastrarColaborador() {
      const payload = {
        id: this.idColaborador,
        idEmpresa: this.user.idEmpresa || 1,
        nome: this.colaborador.nome,
        email: this.colaborador.email,
        cpf: this.colaborador.cpf,
        rg: this.colaborador.rg,
        telefone: this.colaborador.telefone,
        dataNascimento: this.colaborador.dataNascimento,
        statusColaborador: this.colaborador.statusColaborador,
        endereco: {
          rua: this.colaborador.endereco.rua,
          cidade: this.colaborador.endereco.cidade,
          estado: this.colaborador.endereco.estado,
          cep: this.colaborador.endereco.cep
        }
      };

      axios.post(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores`, payload, {
        headers: {
            Authorization: `Bearer ${this.user.token}`
          }
      }).then(response => {
          if (response.status === 201) {
            this.infoMessage = "Colaborador salvo com sucesso.";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack();
              this.resetForm();
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Erro ao salvar colaborador:", error);
          this.errorMessage = "Erro ao salvar colaborador:";
          this.showAlertError = true;
        });
    },
    resetForm() {
      this.colaborador = {
        statusColaborador: 1,
        idEmpresa: this.user.idEmpresa || null,
        idUsuarioCadastro: 67890,
        cpf: '',
        rg: '',
        dataNascimento: '',
        nome: '',
        email: '',
        telefone: '',
        endereco: {
          rua: '',
          cidade: '',
          estado: '',
          cep: ''
        }
      };
    },
    formatDate(dateStr) {
      if (dateStr) {
        const [day, month, year] = dateStr.split('/');
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    goBack() {
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

<template>
  <form @submit.prevent="atualizarServicoColaborador">
    <h6 class="heading-small text-muted mb-4">Informações Básicas</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-12 mt-3 mt-md-0 ">
          <label for="nomeServico">Serviço da Empresa</label>
          <el-select-v2 id="nomeServico" v-model="colaborador_servicos.idServico" loading-text="Buscando"
            no-data-text="Não encontrado" no-match-text="Não encontrado!" clearable filterable remote
            :remote-method="fetchServicosEmpresa" :options="serviceOptions"
            :loading="loading" placeholder="Digite o nome do Serviço para buscar">
          </el-select-v2>
        </div>
        
        
      </div>

      <div class="row mt-2">
        <div class="col-6 col-md-4 mt-2 mt-md-0">
          <label for="valor">Valor</label>
          <label for="valor">Valor (R$):</label>
          <money3 class="form-control" v-model="colaborador_servicos.preco" v-bind="config"></money3>
        </div>
        <div class="col-6 col-md-4 mt-2 mt-md-0 ">
          <label for="hora">Duração</label>
          <input type="time" id="hora" class="form-control" v-model="colaborador_servicos.duracao" />
        </div>
        <div class="col-12 col-md-4 mt-2 mt-md-0">
          <label for="situacaoServico">Status</label>
          <select id="situacaoServico" v-model="colaborador_servicos.situacao" class="form-control">
            <option v-for="status in statusOptions" :key="status.value" :value="status.value">
              {{ status.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack">VOLTAR</a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <a href=" #!" class="btn btn-sm btn-danger btn-responsive" @click.prevent="excluirColaboradorServico">EXCLUIR</a>
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">ATUALIZAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>



<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";
import { Money3Component } from 'v-money3'

export default {
  components: {
    ArgonAlert,
    money3: Money3Component
  },
  props: {
    idColaboradorServico: {
      type: Number,
      required: true,
    },
    idColaborador: {
      type: Number,
      required: true,
    },
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return {
      user: userData,
      colaborador_servicos: {
        id: 0,
        idColaboradorServico: 0,
        idServico: null,
        preco: 0,
        duracao: 0,
        situacao: 1
      },
      statusOptions: [
        { value: 0, label: 'Inativo' },
        { value: 1, label: 'Ativo' },
      ],
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: '',
      serviceOptions: [],
      loading: false,
      modalCadastroServicoColaborador: null,
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: ',',
        decimal: '.',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
    };
  },
  methods: {
    atualizarServicoColaborador() {
      const [hours, minutes] = this.colaborador_servicos.duracao.split(":").map(Number);
      const durationInMinutes = hours * 60 + minutes;
      const payload = {
        id: this.idColaboradorServico,
        idColaborador: this.idColaborador,
        idServico: this.colaborador_servicos.idServico,
        preco: this.colaborador_servicos.preco,
        duracao: durationInMinutes,
        situacao: this.colaborador_servicos.situacao
      };

      axios.put(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos`, payload, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 200) {
            this.infoMessage = "Serviço do Colaborador atualizado com sucesso.";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack();
              this.resetForm();
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Falha ao adicionar serviço ao colaborador:", error);
          this.errorMessage = "Falha ao adicionar serviço ao colaborador";
          this.showAlertError = true;
        });
    },
    fetchServicosEmpresa(searchQuery) {
      if (searchQuery) {
        this.loading = true;

        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/servicos/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa,
            nome: searchQuery,
            pageNo: 0,
            pageSize: 10
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
        .then(response => {
            this.serviceOptions = response.data.servicos.map(servico => ({
              value: servico.id,
              label: servico.nomeServico
            }));
            this.loading = false;
          })
          .catch(error => {
            console.error('Erro ao buscar serviços do colaborador:', error);
            this.serviceOptions = [];
            this.loading = false;
          });
      } else {
        this.serviceOptions = []
      }
    },
    fetchServicoColaborador() {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos/buscar?idColaboradorServico=${this.idColaboradorServico}`, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          const data = response.data;
          this.colaborador_servicos = {
            idColaboradorServico: data.id,
            idServico: data.idServicoPrincipal,
            preco: data.preco,
            duracao: data.duracaoFormatada,
            situacao: data.codigoSituacao
          };
        })
        .catch(error => {
          console.error("Erro ao buscar dados do colaborador:", error);
          this.showAlertError = true;
        });
    },
    resetForm() {
      this.colaborador_servicos = {
        id: 0,
        idColaboradr: 0,
        idServico: null,
        preco: 0,
        duracao: 0,
        situacao: 1
      };
    },
    async excluirColaboradorServico() {
      axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos`, {
        params: {
          idColaboradorServico: this.idColaboradorServico
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      })
      .then(response => {
        if (response.status === 204) {
            this.infoMessage = "Vínculo Colaborador X Servíço excluído com sucesso!"
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack();
            }, 2000);
          }
      })
      .catch(error => {
          console.error("Erro ao excluir colaborador servico:", error);
          this.errorMessage = "Erro ao excluir!";
          this.showAlertError = true;
          setTimeout(() => {
            this.showAlertError = false;
          }, 10000);
        });
    },
    goBack(){
      this.closeModal()
    },
  },
  watch: {
    modalOpened(newValue) {
      if (newValue) {
        this.fetchServicosEmpresa(' ');
        this.fetchServicoColaborador();
      }
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

function logout() {
  localStorage.removeItem('userData');
}

</script>
<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item to="/dashboard" :class="getRoute() === 'Início' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'">
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/colaboradores" :class="getRoute() === 'colaboradores' ? 'active' : ''"
          :navText="'Colaboradores'">
          <template v-slot:icon>
            <i class="fa fa-user-plus text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/clientes" :class="getRoute() === 'clientes' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Clientes'">
          <template v-slot:icon>
            <i class="fa fa-users text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>


      <li class="nav-item">
        <sidenav-item to="/atendimentos" :class="getRoute() === 'atendimentos' ? 'active' : ''"
          :navText="'Atendimentos'">
          <template v-slot:icon>
            <i class="fa fa-list text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/agenda" :class="getRoute() === 'agenda' ? 'active' : ''" :navText="'Agenda'">
          <template v-slot:icon>
            <i class="ni ni-calendar-grid-58 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/financeiro" :class="getRoute() === 'financeiro' ? 'active' : ''" :navText="'Financeiro'">
          <template v-slot:icon>
            <i class="fa fa-money text-info text-sm "></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'">
          صفحات المرافق
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item to="/empresa" :class="getRoute() === 'empresa' ? 'active' : ''" :navText="'Empresa'">
          <template v-slot:icon>
            <i class="fa fa-building text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/servicos" :class="getRoute() === 'servicos' ? 'active' : ''" :navText="'Serviços'">
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item" >
        <sidenav-item to="/signin" @click="logout" :class="getRoute() === 'Sair' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Sair'">
          <template v-slot:icon>
            <i class="fa fa-sign-out text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> 
    </ul>
  </div>

  <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card :card="{
          title: 'Need Help?',
          description: 'Please check our docs',
          links: [
            {
              label: 'Documentation',
              route:
                'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
              color: 'dark',
            },
            {
              label: 'Buy now',
              route:
                'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
              color: 'success',
            },
          ],
        }" />
  </div> -->
</template>

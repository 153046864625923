<template>
  <form @submit.prevent="atualizarEmpresa">
    <h6 class="heading-small text-muted mb-4">Informações Básicas</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label for="nomeEmpresa">Nome</label>
          <input type="text" id="nomeEmpresa" class="form-control" placeholder="Nome da Empresa" v-model="empresa.nome"
            required />
        </div>
        <div class="col-12 col-md-4">
          <label for="descricaoEmpresa">Descrição</label>
          <input type="text" id="descricaoEmpresa" class="form-control" placeholder="Descrição da Empresa" v-model="empresa.descricao"
            required />
        </div>
        <div class="col-12 col-md-4 mt-2 mt-md-0">
          <label for="emailEmpresa">Email</label>
          <input type="email" id="emailEmpresa" class="form-control" placeholder="email@exemplo.com"
            v-model="empresa.email" />
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="cnpjEmpresa">CNPJ</label>
          <input type="text" id="cnpjEmpresa" class="form-control" placeholder="00.000.000/0000-00"
            v-model="empresa.cnpj" />
        </div>
        <div class="col-12 col-md-3">
          <label for="razaoSocialEmpresa">Razão Social</label>
          <input type="text" id="razaoSocialEmpresa" class="form-control" placeholder="Razão Social"
            v-model="empresa.razaoSocial" />
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="telefoneEmpresa">Telefone</label>
          <input type="text" name="phone" id="telefoneEmpresa" class="form-control" required v-model="empresa.telefone" v-mask-phone.br>
        </div>
        <div class="col-12 col-md-3 mt-2 mt-md-0">
          <label for="statusEmpresa">Status</label>
          <select v-model="empresa.statusEmpresa" id="statusEmpresa" class="form-control">
            <option value="Ativo">Ativo</option>
            <option value="Inativo">Inativo</option>
          </select>
        </div>
      </div>
    </div>

    <hr class="my-4">

    <h6 class="heading-small text-muted">Endereço</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="ruaEmpresa">Rua</label>
          <input type="text" id="ruaEmpresa" class="form-control" placeholder="Nome da Rua"
            v-model="empresa.endereco.rua" />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="cidadeEmpresa">Cidade</label>
          <input type="text" id="cidadeEmpresa" class="form-control" placeholder="Nome da Cidade"
            v-model="empresa.endereco.cidade" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <label for="estadoEmpresa">Estado</label>
          <input type="text" id="estadoEmpresa" class="form-control" placeholder="Nome do Estado"
            v-model="empresa.endereco.estado" />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="cepEmpresa">CEP</label>
          <input type="text" id="cepEmpresa" class="form-control" placeholder="00000-000"
            v-model="empresa.endereco.cep" />
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack">VOLTAR</a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">ATUALIZAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  components: {
    ArgonAlert
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};

    return {
      user: userData,
      empresa: {
        nome: '',
        descricao: '',
        email: '',
        cnpj: '',
        razaoSocial: '',
        telefone: '',
        dataCadastro: null,
        statusEmpresa: 'Ativo',
        endereco: {
          rua: '',
          cidade: '',
          estado: '',
          cep: ''
        }
      },
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    fetchEmpresaData() {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/empresas?idEmpresa=${this.user.idEmpresa}`, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          const data = response.data;
          this.empresa = {
            nome: data.nome || '',
            descricao: data.descricao || '',
            email: data.email || '',
            cnpj: data.cnpj || '',
            razaoSocial: data.razaoSocial || '',
            telefone: data.telefone || '',
            dataCadastro: this.formatDate(data.dataCadastro),
            statusEmpresa: data.statusEmpresa === 'Ativo' ? 'Ativo' : 'Inativo',
            endereco: {
              rua: data.endereco.rua || '',
              cidade: data.endereco.cidade || '',
              estado: data.endereco.estado || '',
              cep: data.endereco.cep || ''
            }
          };
        })
        .catch(error => {
          console.error("Erro ao buscar dados da empresa:", error);
          this.showAlertError = true;
        });
    },
    atualizarEmpresa() {
      const payload = {
        id: this.user.idEmpresa,
        nome: this.empresa.nome,
        descricao: this.empresa.descricao,
        email: this.empresa.email,
        cnpj: this.empresa.cnpj,
        razaoSocial: this.empresa.razaoSocial,
        telefone: this.empresa.telefone,
        dataCadastro: this.empresa.dataCadastro,
        statusEmpresa: this.empresa.statusEmpresa === 'Ativo' ? 1 : 0,
        endereco: {
          rua: this.empresa.endereco.rua,
          cidade: this.empresa.endereco.cidade,
          estado: this.empresa.endereco.estado,
          cep: this.empresa.endereco.cep
        }
      };

      axios.put(`${process.env.VUE_APP_API_ENDPOINT}/empresas`, payload, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 200) {
            this.infoMessage = "Empresa Atualizada com sucesso.";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack();
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar empresa:", error);
          this.errorMessage = error.response.data.descricao;
          this.showAlertError = true;
        });
    },
    formatDate(dateStr) {
      if (dateStr) {
        const [day, month, year] = dateStr.split('/');
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {
    this.fetchEmpresaData();
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

<template>
  <form @submit.prevent="cadastrarAtendimento">
    <!-- <h6 class="heading-small text-muted mb-0 mb-md-2">Informações do Atendimento</h6>
    <hr> -->

    <div>
      <div class="row">
        <div class="col-12 col-md-6 mt-0 mt-md-0 ">
          <label for="nomeCliente" class="mt-md-4">Cliente</label>
          <button type="button" class="btn btn-light btn-sm p-3 w-100 w-md-auto" style="float:right;"
            @click="cadastrarClientesEmpresa">
            Cadastrar Cliente
          </button>
          <el-select-v2 id="nomeCliente" v-model="selectedClient" loading-text="Buscando" no-data-text="Não encontrado"
            no-match-text="Não encontrado!" clearable filterable remote :remote-method="fetchClientesFiltro"
            :options="clientOptions" :loading="loading" placeholder="Digite o nome do Cliente para buscar">
          </el-select-v2>
        </div>

        <div class="col-12 col-md-6 mt-0 mt-md-0">
          <label for="nomeColaborador" class="mt-4">Colaborador</label>
          <el-select-v2 id="nomeColaborador" class="mt-md-3" v-model="selectedColaborador" loading-text="Buscando"
            no-data-text="Não encontrado" no-match-text="Não encontrado!" clearable filterable remote
            :remote-method="fetchColaboradoresFiltro" @change="onEmployeeChange()" :options="colaboradorOptions"
            :loading="loading" placeholder="Digite o nome do Colaborador para buscar">
          </el-select-v2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mt-3 mt-md-0 ">
          <label for="nomeServico">Serviço</label>
          <el-select-v2 id="nomeServico" v-model="service.selectedService" loading-text="Buscando"
            no-data-text="Não encontrado" no-match-text="Não encontrado!" clearable filterable remote
            :remote-method="fetchServicosColaborador" :disabled="!selectedColaborador" :options="serviceOptions"
            :loading="loading" placeholder="Digite o nome do Serviço para buscar">
          </el-select-v2>
        </div>
        <div class="col-12 col-md-3 mt-3 mt-md-0 ">
          <label for="data">Data</label>
          <input type="date" id="data" class="form-control p-3" v-model="service.data" required/>
        </div>
        <div class="col-12 col-md-3 mt-3 mt-md-0 ">
          <label for="hora">Horário</label>
          <input type="time" id="hora" class="form-control p-3" v-model="service.horario" required/>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-md btn-primary btn-responsive" @click="goBack"><i class="fa fa-chevron-left"></i></a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <button type="submit" class="btn btn-md btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">SALVAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>

  <div class="modal fade" ref="modalCadastrarCliente" tabindex="0" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm mb-6">
      <div class="modal-content">
        <div class="modal-header text-xs text-secondary mb-0">
          <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Cliente</h5>
          <button type="button" class="btn-close" style="color: black; font-size: 0.9rem"
            @click="closeModalCadastroCliente">X</button>
        </div>
        <div class="modal-body p-4">
          <CadastrarClienteResumoForm :closeModal="closeModalCadastroCliente" :modalOpened="modalCadastrarClienteOpened" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";
import CadastrarClienteResumoForm from "../clientes/CadastrarClienteResumoForm.vue";
import { Modal } from 'bootstrap';


export default {
  components: {
    ArgonAlert,
    CadastrarClienteResumoForm
  },
  props: {
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    },
    dateSelected: {
      type: String,
      required: false
    },
    timeSelected: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')),
      service: {
        nomeServico: '',
        descricaoServico: '',
        tipoServico: '',
        situacao: 1,
        idEmpresa: null,
        selectedService: null,
        data: null,
        horario: null
      },
      showAlert: false,
      showAlertError: false,
      selectedClient: null,
      clientOptions: [],
      selectedColaborador: null,
      selectOptions: [],
      colaboradorOptions: [],
      serviceOptions: [],
      loading: false,
      modalCadastrarClienteOpened: false,
      modalCadastrarCliente: null,
    };
  },
  mounted() {
    this.modalCadastrarCliente = new Modal(this.$refs.modalCadastrarCliente);
  },
  methods: {
    fetchColaboradoresFiltro(query) {
      const pageNo = 0
      //console.log(query)
      
        this.loadingColaborador = true;
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa || 1,
            nome: query,
            pageNo: pageNo,
            pageSize: this.pageSize
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        }).then(response => {
            this.colaboradorOptions = response.data.colaboradores.map(colaborador => ({
              value: colaborador.id,
              label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
            }));
            this.loadingColaborador = false;
            this.colaboradores = response.data.colaboradores;
            this.totalElements = response.data.paginacao.totalElements;
            this.totalPages = Math.ceil(this.totalElements / this.pageSize);
          })
          .catch(() => {
            this.loadingColaborador = false;
          });
      
    },
    onEmployeeChange(value) {
      if (value) {
        this.fetchServicosColaborador(value);
      } else {
        this.serviceOptions = [];
      }
      this.service.selectedService = null;
    },
    fetchServicosColaborador() {
      this.loading = true;

      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos`, {
        params: {
          idColaborador: this.selectedColaborador,
          pageNo: 0,
          pageSize: 10
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          this.serviceOptions = response.data.servicos.map(servico => ({
            value: servico.id,
            label: `${servico.nomeServico} | R$: ${servico.preco}`
          }));
          this.loading = false;
        })
        .catch(error => {
          console.error('Erro ao buscar serviços do colaborador:', error);
          this.serviceOptions = [];
          this.loading = false;
        });
    },
    fetchClientesFiltro(query) {
      
        this.loading = true;
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/clientes/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa,
            nome: query,
            pageNo: 0,
            pageSize: this.pageSize
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
          .then(response => {
            this.clientOptions = response.data.clientes.map(cliente => ({
              value: cliente.id,
              label: `${cliente.nome} | Contato: ${cliente.telefone || ''}`
            }));
            this.loading = false;
          })
          .catch(error => {
            console.error('Erro ao buscar clientes:', error);
            this.loading = false;
          });
      
    },
    async cadastrarAtendimento() {
      try {
        const dataSelecionada = new Date(this.service.data);
        const formattedDate = dataSelecionada.toISOString().split('T')[0].split('-').reverse().join('/');

        if(this.service.selectedService == null || this.selectedClient == null){
          this.errorMessage = "Preencha todos os campos";
          this.showAlert = false;
          this.showAlertError = true;
          setTimeout(() => {
            this.showAlertError = false;
          }, 2000);
          return
        }

        const payload = {
          idColaboradorServico: this.service.selectedService,
          idCliente: this.selectedClient,
          data: formattedDate,
          hora: this.service.horario
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/agenda/salvar_servico_agenda_colaborador`,
          payload,{
            headers: {
              Authorization: `Bearer ${this.user.token}`
            }
          }
        );

        if (response.status === 200 || response.status === 201) {
          this.infoMessage = "Atendimento salvo com sucesso.";
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
            this.goBack();
          }, 2000);
        }
      } catch (error) {
        console.error("Erro ao salvar o atendimento:", error);
        this.errorMessage = error.response.data;
        this.showAlert = false;
        this.showAlertError = true;
        setTimeout(() => {
          this.showAlertError = false;
        }, 2000);
      }
    },
    cadastrarClientesEmpresa() {
      this.modalCadastrarCliente.show();
      this.modalCadastrarClienteOpened = true;
    },
    closeModalCadastroCliente() {
      this.modalCadastrarCliente.hide();
      this.modalCadastrarClienteOpened = false;
      this.selectedClient = null;
      this.selectedColaborador = null;
      this.service.selectedService = null;
    },
    goBack() {
      this.selectedClient = null;
      this.selectedColaborador = null;
      this.service.selectedService = null;
      this.closeModal();
    }
  },
  watch: {
    modalOpened() {
      this.service.data = this.dateSelected 
        ? new Date(this.dateSelected.split('/').reverse().join('-')).toISOString().split('T')[0] 
        : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0];
      this.service.horario = this.timeSelected || new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
      console.log(this.service.data)
      console.log(this.service.horario)
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

<template>
  <form @submit.prevent="cadastrarCliente">
    <div>
      <div class="row">
        <div class="col-12 col-md-12">
          <label for="nomeCliente">Nome</label>
          <input type="text" id="nomeCliente" class="form-control" placeholder="Nome do Cliente" v-model="cliente.nome"
            required />
        </div>
        <div class="col-12 col-md-12 mt-3 mt-md-0">
          <label for="telefoneCliente">Telefone</label>
          <input type="text" name="phone" id="telefoneCliente" required class="form-control" placeholder="(00) 0 0000-0000" v-model="cliente.telefone" v-mask-phone.br>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-md btn-primary btn-responsive" @click="goBack"><i class="fa fa-chevron-left"></i></a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">SALVAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  components: {
    ArgonAlert
  },
  props: {
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return {
      user: userData,
      cliente: {
        status: 1,
        cpf: '',
        rg: '',
        dataNascimento: null,
        nome: '',
        email: '',
        telefone: '',
        endereco: {
          rua: '',
          cidade: '',
          estado: '',
          cep: ''
        }
      },
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    cadastrarCliente() {
      const payload = {
        idEmpresa: this.user.idEmpresa || 1,
        nome: this.cliente.nome,
        email: this.cliente.email,
        cpf: this.cliente.cpf,
        rg: this.cliente.rg,
        telefone: this.cliente.telefone,
        dataNascimento: this.cliente.dataNascimento,
        status: this.cliente.status,
        endereco: {
          rua: this.cliente.endereco.rua,
          cidade: this.cliente.endereco.cidade,
          estado: this.cliente.endereco.estado,
          cep: this.cliente.endereco.cep
        }
      };

      axios.post(`${process.env.VUE_APP_API_ENDPOINT}/clientes`, payload, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 201) {
            this.infoMessage = "Cliente salvo com sucesso.";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack();
              this.resetForm();
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Erro ao salvar cliente:", error);
          this.errorMessage = "Erro ao salvar cliente:";
          this.showAlertError = true;
        });
    },
    resetForm() {
      this.cliente = {
        status: 1,
        cpf: '',
        rg: '',
        dataNascimento: '',
        nome: '',
        email: '',
        telefone: '',
        endereco: {
          rua: '',
          cidade: '',
          estado: '',
          cep: ''
        }
      };
    },
    goBack() {
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>

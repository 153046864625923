import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Colaboradores from "../views/Colaboradores.vue";
import Clientes from "../views/Clientes.vue";
import Atendimentos from "../views/Atendimentos.vue";
import Financeiro from "../views/Financeiro.vue";
import Agenda from "../views/Agenda.vue";
import Servicos from "../views/Servicos.vue";
import Empresa from "../views/Empresa.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true },
  },
  {
    path: "/colaboradores",
    name: "Colaboradores",
    component: Colaboradores,
    meta: { requiresAuth: true },
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: { requiresAuth: true },
  },
  {
    path: "/atendimentos",
    name: "Atendimentos",
    component: Atendimentos,
    meta: { requiresAuth: true },
  },
  {
    path: "/agenda",
    name: "Agenda",
    component: Agenda,
    meta: { requiresAuth: true },
  },
  {
    path: "/financeiro",
    name: "Financeiro",
    component: Financeiro,
    meta: { requiresAuth: true },
  },
  {
    path: "/servicos",
    name: "Serviços",
    component: Servicos,
    meta: { requiresAuth: true },
  },
  {
    path: "/empresa",
    name: "Empresa",
    component: Empresa,
    meta: { requiresAuth: true },
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { requiresAuth: true },
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: { requiresAuth: true },
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { requiresAuth: false },
  },
];

async function isAuthenticated() {

  const userData = localStorage.getItem("userData");

  if (!userData) {
    return false;
  }

  try {
    const parsedData = JSON.parse(userData);
    const token = parsedData.token;

    if (token) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ENDPOINT}/usuarios/login/validate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.status === 200;
    }
  } catch (error) {
    console.error("Erro ao validar o token:", error);
    return false;
  }

  return false;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const isValid = await isAuthenticated();

    if (!isValid) {
      next({ name: "Signin" });
      return;
    }
  }
  next();
});


export default router;

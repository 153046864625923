<template>
  <div class="card">
    <div class="card-header pb-0 border-0">
      <div class="row">
        <div class="col-12 col-md-4">
          <h3 class="">Colaboradores</h3>
        </div>

        <div class="col-12 col-md-9 mt-3 mt-md-0 text-md-center">
          <el-select-v2 v-model="selectedEmployee" loading-text="Buscando" no-data-text="Não encontrado"
            no-match-text="Não encontrado!" clearable filterable remote :remote-method="fetchColaboradoresFiltro"
            @change="visualizar(selectedEmployee)" :options="employeeOptions" :loading="loading"
            placeholder="Digite o nome do Colaborador para buscar">
          </el-select-v2>
        </div>

        <div class="col-12 col-md-3 mt-3 mt-md-0">
          <button type="button" class="btn btn-success btn-sm w-100 w-md-auto" style="float:right;"
            @click="cadastrarColaborador">
            Novo
          </button>
        </div>
      </div>
    </div>
    <hr class="my-1 " />

    <div class="card-body px-0 pt-0 pb-0">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Telefone</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(colaborador, index) in colaboradores" :key="index" @click="visualizar(colaborador.id)"
              style="cursor: pointer;">
              <td class="nome-width">
                <div class="d-flex px-3 py-1 ">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ colaborador.nome }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-left text-sm d-none d-md-table-cell">
                <p class="text-xs text-secondary mb-0">{{ colaborador.telefone }}</p>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="4" class="text-right">
                <div class="d-flex justify-content-end mt-3" style="margin-right: 25px;">
                  <argon-pagination>
                    <argon-pagination-item prev @click="prevPage" :disabled="currentPage === 1" />
                    <argon-pagination-item v-for="page in pagesToShow" :key="page" :label="String(page)"
                      :active="page === currentPage" @click="handlePageChange(page)" />
                    <argon-pagination-item next @click="nextPage" :disabled="currentPage === totalPages" />
                  </argon-pagination>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalVisualizarColaborador" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl mb-6">
      <div class="modal-content">
        <div class="modal-header text-xs text-secondary mb-0">
          <h5 class="modal-title" style="margin-left: 10px;">Visualizar Colaborador</h5>
          <button type="button" class="btn-close" style="color: black; font-size: 0.9rem" @click="closeModal">X</button>
        </div>
        <div class="modal-body p-4">
          <VisualizarColaboradorForm :idColaborador="idColaboradorSelecionado" :closeModal="closeModal"
            :modalOpened="modalOpened" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalCadastrarColaborador" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl mb-6">
      <div class="modal-content">
        <div class="modal-header text-xs text-secondary mb-0">
          <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Colaborador</h5>
          <button type="button" class="btn-close" style="color: black; font-size: 0.9rem" @click="closeModal">X</button>
        </div>
        <div class="modal-body p-4">
          <CadastrarColaboradorForm :closeModal="closeModal" :modalOpened="modalOpened" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import VisualizarColaboradorForm from "../colaboradores/VisualizarColaboradorForm.vue";
import CadastrarColaboradorForm from "../colaboradores/CadastrarColaboradorForm.vue";
import { Modal } from 'bootstrap';


export default {
  name: 'listar-colaboradores-empresa',
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    VisualizarColaboradorForm,
    CadastrarColaboradorForm
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};

    return {
      user: userData,
      currentPage: 1,
      pageSize: 4,
      colaboradores: [],
      idColaboradorSelecionado: 0,
      totalElements: 0,
      totalPages: 0,
      modal: null,
      modalCadastroColaborador: null,
      modalOpened: false,
      modalCadastroColaboradorOpened: false,
      nomeColaborador: '',
      selectedEmployee: null,
      employeeOptions: [],
      loading: false,
      idEmpresaSelecionado: 1
    };
  },
  computed: {
    pagesToShow() {
      if (this.totalPages <= 3) {
        return Array.from({ length: this.totalPages }, (v, i) => i + 1);
      } else {
        if (this.currentPage === 1) {
          return [1, 2, 3];
        } else if (this.currentPage === this.totalPages) {
          return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
        } else {
          return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
        }
      }
    }
  },
  mounted() {
    this.fetchColaboradoresFiltro();
    this.modal = new Modal(this.$refs.modalVisualizarColaborador);
    this.modalCadastroColaborador = new Modal(this.$refs.modalCadastrarColaborador);
  },
  methods: {
    async fetchColaboradores(pageZero) {
      this.idEmpresaSelecionado = this.user.idEmpresa || 1;
      var pageNo = this.currentPage - 1;

      if (pageZero) {
        pageNo = 0
        this.currentPage = 1
      }

      try {
        const url = `${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa?idEmpresa=${this.idEmpresaSelecionado}&pageNo=${pageNo}&pageSize=${this.pageSize}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        });

        this.colaboradores = response.data.colaboradores;
        this.totalElements = response.data.paginacao.totalElements;
        this.totalPages = Math.ceil(this.totalElements / this.pageSize);
      } catch (error) {
        console.error('Erro ao buscar colaboradores:', error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchColaboradores();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.handlePageChange(this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchColaboradores();
      }
    },
    cadastrarColaborador() {
      this.modalCadastroColaborador.show();
      this.modalCadastroColaboradorOpened = true;
    },
    visualizar(colaboradorId) {
      if (colaboradorId) {
        // console.log("colaborador id:", colaboradorId)
        this.idColaboradorSelecionado = colaboradorId;
        this.modal.show();
        this.modalOpened = true;
        this.selectedEmployee = null;
      }
    },
    closeModal() {
      this.modal.hide();
      this.modalOpened = false;
      this.modalCadastroColaborador.hide();
      this.modalCadastroColaboradorOpened = false;
      this.fetchColaboradores(true);
    },
    fetchColaboradoresFiltro(query) {
      const pageNo = 0
      if (query) {
        this.loading = true;
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa || 1,
            nome: query,
            pageNo: pageNo,
            pageSize: this.pageSize
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
          .then(response => {
            this.employeeOptions = response.data.colaboradores.map(colaborador => ({
              value: colaborador.id,
              label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
            }));
            this.loading = false;
            this.colaboradores = response.data.colaboradores;
            this.totalElements = response.data.paginacao.totalElements;
            this.totalPages = Math.ceil(this.totalElements / this.pageSize);
          })
          .catch(() => {
            this.loading = false;
            this.fetchColaboradores()
          });
      } else {
        this.fetchColaboradores();
      }

    },
  }
};
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

h3 {
  font-size: 1rem;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.0rem;
  }

  .btn-responsive {
    margin-top: 5px;
    font-size: 0.775rem;
    padding: 0.65rem 0.6rem;
  }
}

@media (max-width: 414px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .nome-width {
    min-width: 230px;
  }
}
</style>

<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <mini-statistics-card @click="visualizar" :title="empresa.descricao + ' - ' + empresa.cnpj"
              :value="empresa.nome" description="Clique para editar" style="cursor: pointer;" />
          </div>
        </div>

        <!--
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Today's Money" value="$53,000" description="<span
                class='text-sm font-weight-bolder text-success'
                >+55%</span> since yesterday" :icon="{
              component: 'ni ni-money-coins',
              background: 'bg-gradient-primary',
              shape: 'rounded-circle',
            }
              " />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Today's Users" value="2,300" description="<span
                class='text-sm font-weight-bolder text-success'
                >+3%</span> since last week" :icon="{
              component: 'ni ni-world',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }
              " />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="New Clients" value="+3,462" description="<span
                class='text-sm font-weight-bolder text-danger'
                >-2%</span> since last quarter" :icon="{
              component: 'ni ni-paper-diploma',
              background: 'bg-gradient-success',
              shape: 'rounded-circle',
            }
              " />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Sales" value="$103,430" description="<span
                class='text-sm font-weight-bolder text-success'
                >+5%</span> than last month" :icon="{
              component: 'ni ni-cart',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }
              " />
          </div>
        </div> -->

        <div class="row">
          <div class="col-lg-12 mb-lg">
            <div class="card">
              <ListarAtendimentosEmpresaResumo></ListarAtendimentosEmpresaResumo>
            </div>
          </div>
          <!--
          <div class="col-lg-5 ">
            <gradient-line-chart id="chart-line" title="Resumo Financeiro" description="<i class='fa fa-arrow-up text-success'></i>
              <span class='font-weight-bold'>4% a mais</span> em setembro" :chart="{
              labels: [
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              datasets: [
                {
                  label: 'Mobile Apps',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
              ],
            }
              " />
          </div>-->
        </div>

        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <ListarClientesEmpresaResumo></ListarClientesEmpresaResumo>
          </div>
          <div class="col-lg-5">
            <ListarColaboradoresEmpresaResumo></ListarColaboradoresEmpresaResumo>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import ListarClientesEmpresaResumo from './components/clientes/ListarClientesEmpresaResumo.vue';
import ListarColaboradoresEmpresaResumo from './components/colaboradores/listarColaboradoresEmpresaResumo.vue';
import ListarAtendimentosEmpresaResumo from './components/atendimentos/ListarAtendimentosEmpresaResumo.vue';

import axios from "axios";

export default {
  components: {
    MiniStatisticsCard,
    // GradientLineChart,
    ListarClientesEmpresaResumo,
    ListarColaboradoresEmpresaResumo,
    ListarAtendimentosEmpresaResumo
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};

    return {
      user: userData,
      empresa: {
        nome: '',
        email: '',
        cnpj: '',
        razaoSocial: '',
        telefone: '',
        descricao: '',
        dataCadastro: null,
        statusEmpresa: 'Ativo',
        endereco: {
          rua: '',
          cidade: '',
          estado: '',
          cep: ''
        }
      }
    };
  },
  methods: {
    visualizar() {
      this.$router.push({ path: "/empresa" });
    },
    async fetchEmpresaData() {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/empresas?idEmpresa=${this.user.idEmpresa}`,{
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      })
        .then(response => {
          const data = response.data;
          this.empresa = {
            nome: data.nome || '',
            email: data.email || '',
            cnpj: data.cnpj || '',
            razaoSocial: data.razaoSocial || '',
            telefone: data.telefone || '',
            descricao: data.descricao,
            statusEmpresa: data.statusEmpresa === 'Ativo' ? 'Ativo' : 'Inativo',
            endereco: {
              rua: data.endereco.rua || '',
              cidade: data.endereco.cidade || '',
              estado: data.endereco.estado || '',
              cep: data.endereco.cep || ''
            }
          };
        })
        .catch(error => {
          console.error("Erro ao buscar dados da empresa:", error);
          this.showAlertError = true;
        });
    },
  },
  mounted() {
    this.fetchEmpresaData();
  },
};
</script>

<style scoped></style>

<template>
  <form @submit.prevent="atualizarServico">
    <h6 class="heading-small text-muted mb-4">Informações do Serviço</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="nomeServico">Nome do Serviço</label>
          <input type="text" id="nomeServico" class="form-control" placeholder="Nome do Serviço"
            v-model="servico.nomeServico" required />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="descricaoServico">Descrição</label>
          <input type="text" id="descricaoServico" class="form-control" placeholder="Descrição do Serviço"
            v-model="servico.descricaoServico" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <label for="tipoServico">Tipo de Serviço</label>
          <input type="text" id="tipoServico" class="form-control" placeholder="Tipo de Serviço"
            v-model="servico.tipoServico" />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <label for="situacaoServico">Situação</label>
          <select v-model="servico.situacao" id="situacaoServico" class="form-control">
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack">VOLTAR</a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <a href=" #!" class="btn btn-sm btn-danger btn-responsive" @click.prevent="excluirServico">EXCLUIR</a>
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">ATUALIZAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>

<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  components: {
    ArgonAlert
  },
  props: {
    idServico: {
      type: Number,
      required: true,
    },
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')) || {},
      servico: {
        nomeServico: '',
        descricaoServico: '',
        preco: '',
        tipoServico: '',
        situacao: 1,
      },
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    fetchServico() {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/servicos?idServico=${this.idServico}`, {
        headers: {
            Authorization: `Bearer ${this.user.token}`
          }
      }).then(response => {
          const data = response.data;
          this.servico = {
            nomeServico: data.nomeServico || '',
            descricaoServico: data.descricaoServico || '',
            preco: data.preco || '',
            tipoServico: data.tipoServico || '',
            situacao: data.situacao === 1 ? 1 : 0
          };
        })
        .catch(error => {
          console.error("Erro ao buscar dados do serviço:", error);
          this.showAlertError = true;
        });
    },
    atualizarServico() {
      const payload = {
        id: this.idServico,
        nomeServico: this.servico.nomeServico,
        descricaoServico: this.servico.descricaoServico,
        preco: this.servico.preco,
        tipoServico: this.servico.tipoServico,
        situacao: this.servico.situacao
      };

      axios.put(`${process.env.VUE_APP_API_ENDPOINT}/servicos`, payload, {
        headers: {
            Authorization: `Bearer ${this.user.token}`
          }
      }).then(response => {
          if (response.status === 200) {
            this.infoMessage = "Serviço atualizado com sucesso.";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack();
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar serviço:", error);
          this.errorMessage = "Erro ao atualizar serviço:";
          this.showAlertError = true;
        });
    },
    async excluirServico() {
      axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/servicos`, {
        params: {
          idServico: this.idServico
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 204) {
            this.infoMessage = "Serviço excluído com sucesso";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
              this.goBack('excluir');
            }, 2000);
          }
        })
        .catch(error => {
          console.error("Erro ao excluir serviço:", error);
          this.errorMessage = "Erro ao excluir serviço:";
          this.showAlertError = true;
          setTimeout(() => {
            this.showAlertError = false;
          }, 10000);
        });
    },
    goBack(acao) {
      this.closeModal(acao);
    }
  },
  watch: {
    modalOpened(newValue) {
      if (newValue) {
        this.fetchServico();
      }
    }
  }
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>
